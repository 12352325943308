<template>
  <div class="card p-10">
    <div class="d-flex justify-content-between">
      <div>
        <h1 class="d-flex">
          <div style="width: 120px">裝置:</div>
          {{ device.name }}
        </h1>
        <h1 class="d-flex">
          <div style="width: 120px">裝置情境:</div>
          {{ deviceScenarioName }}
        </h1>
      </div>

      <div>
        <button
          @click="addScenarioVisible = true"
          type="button"
          class="btn btn-primary"
        >
          新增執行點位
        </button>
      </div>
    </div>
    <Datatable
      :table-data="tableData"
      :table-header="tableHeader"
      :enable-items-per-page-dropdown="true"
      :openTextEnd="false"
      :striped="true"
    >
      <template v-slot:cell-sortOrder="{ row: tableData }">
        <span :guid="tableData.data.guid">{{ tableData.sortOrder }}</span>
      </template>
      <template v-slot:cell-name="{ row: tableData }">
        {{ tableData.name }}
      </template>
      <template v-slot:cell-dataPointName="{ row: tableData }">
        {{ tableData.dataPointName }}
      </template>
      <template v-slot:cell-command="{ row: tableData }">
        {{ tableData.command }}
      </template>
      <template v-slot:cell-createTime="{ row: tableData }">
        {{ tableData.createTime }}
      </template>
      <template v-slot:cell-operate="{ row: tableData }">
        <el-dropdown>
          <span
            @mouseover="setCurrentItem(tableData)"
            class="el-dropdown-link text-primary"
          >
            功能選單<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="deleteItem()">刪除</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
    </Datatable>
  </div>
  <AddDeviceScenarioMappingDialog
    v-if="addScenarioVisible"
    v-model="addScenarioVisible"
    :deviceGuid="deviceGuid"
    :deviceScenarioGuid="deviceScenarioGuid"
    @onAdd="onAdd()"
  ></AddDeviceScenarioMappingDialog>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import {
  getDeviceScenarioMappings,
  deleteDeviceScenarioMappings,
} from "@/core/services/api/deviceScenarioMapping";
import { useRoute } from "vue-router";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getDataPointCommandByDevice } from "@/core/services/api/dataPointCommand";
import { getTargetDevice } from "@/core/services/api/device";
import { getLocalTimeString } from "@/core/utils/timeString";
import AddDeviceScenarioMappingDialog from "./AddDeviceScenarioMappingDialog.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
    AddDeviceScenarioMappingDialog,
  },
  setup() {
    const addScenarioVisible = ref(false);
    const route = useRoute();
    const mappings: any = reactive([]);
    const deviceGuid = route.params.deviceGuid;
    const deviceScenarioGuid = route.params.scenarioGuid;
    const deviceScenarioName = route.params.scenarioName;
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "排序",
        key: "sortOrder",
        sortable: true,
      },
      {
        name: "名稱",
        key: "name",
        sortable: true,
      },
      {
        name: "點位",
        key: "dataPointName",
        sortable: true,
      },
      {
        name: "指令",
        key: "command",
        sortable: true,
      },
      {
        name: "建立時間",
        key: "createTime",
        sortable: true,
      },
      {
        name: "功能",
        key: "operate",
        sortable: true,
      },
    ]);
    const dataPointCommands: any = reactive([]);
    const device: any = reactive({});
    const typeMap = {
      AliveStatus: "存活狀態",
      Power: "電源狀態",
      AirMode: "冷氣模式",
      AirFanSpeed: "冷氣風速",
      AirTemperatureSetting: "冷氣溫度設定",
      AirTemperatureIndoor: "冷氣室內溫度",
      DoorLock: "門鎖",
      BatteryPercentage: "電池",
      LightLevel: "亮度",
      LightColorTemperature: "色溫",
      AirPanelLock: "冷氣面板控制",
            MoveUp: "向上轉動",
            MoveDown: "向下轉動",
            MoveStop: "窗簾停止",
            MoveToPosition: "移動至指定位置",
            CurrentPosition: "當前位置",
    };
    const enumerationMap = {
      UnKnow: "未知",
      Online: "線上",
      Offline: "離線",
      On: "電源開啟",
      Off: "電源關閉",
      Locked: "上鎖",
      UnLocked: "解鎖",
      AirCondition: "冷氣",
      Dehumidify: "除溼",
      Ventilation: "送風",
      Automatic: "自動",
      Heating: "暖氣",
      Manual: "手動",
        PanelUnLocked: "面板未鎖定",
            PanelLocked: "面板鎖定",
            StandBy: "等待",
            Down: "向下",
            Up: "向上",
            Stop: "停止",
            MoveToPosition: "移動至指定位置",
    };
    const currentItem: any = reactive({});

    const setMappings = async () => {
      let request: any = await getDeviceScenarioMappings(
        deviceGuid,
        deviceScenarioGuid
      );
      mappings.splice(0, mappings.length, ...request);
      console.log("mappings", mappings);
    };

    const setDeviceDataPointCommand = async () => {
      let request = await getDataPointCommandByDevice(deviceGuid);
      dataPointCommands.splice(0, dataPointCommands.length, ...request);
    };

    // 根據點位guid取得資料
    const getDataPointCommandByGuid = (guid) => {
      let filter = dataPointCommands.filter((o) => o.guid == guid);
      return filter[0];
    };

    // 根據點位guid 取得data
    const getDataPointByGuid = (guid) => {
      let enumFilter = device.enumDataPoints.filter((o) => o.guid == guid);
      let rangeFilter = device.rangeDataPoints.filter((o) => o.guid == guid);
      if (enumFilter.length > 0) {
        return enumFilter[0];
      } else if (rangeFilter.length > 0) {
        return rangeFilter[0];
      }
    };

    // 根據valueType取得項目指令
    const getCommandByValueType = (valueType, command) => {
      if (valueType == "Enum") {
        return enumerationMap[JSON.parse(command.enumCommand).name];
      } else if (valueType == "Range") {
        return command.rangeCommand;
      }
    };

    const setTableData = () => {
      tableData.splice(0);
      for (const item of mappings) {
        let dataPointCommand = getDataPointCommandByGuid(item.dataPointCommand);
        let dataPoint = getDataPointByGuid(dataPointCommand.dataPoint);
        console.log("dataPointCommand", dataPointCommand);
        tableData.push({
          data: item,
          sortOrder: item.sortOrder,
          name: dataPointCommand.name,
          dataPointName: typeMap[dataPoint.type],
          command: getCommandByValueType(dataPoint.valueType, dataPointCommand),
          createTime: getLocalTimeString(item.createTime),
        });
        console.log("tableData", tableData);
      }
    };

    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    const onAdd = () => {
      addScenarioVisible.value = false;
      init();
    };

    const setDevice = async () => {
      let request = await getTargetDevice(deviceGuid);
      Object.assign(device, request);
    };

    const deleteItem = async () => {
      Swal.fire({
        title: `您確定要刪除 "執行點位:${currentItem.name}" 嗎?`,
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteDeviceScenarioMappings(
            deviceGuid,
            currentItem.data.guid
          ).then(async () => {
            await Swal.fire("移除成功!", "", "success");
            init();
          });
        }
      });
    };

    const init = async () => {
      await setMappings();
      await setDeviceDataPointCommand();
      await setDevice();
      setTableData();
    };
    init();

    return {
      tableData,
      initTableData,
      tableHeader,
      device,
      deviceScenarioName,
      addScenarioVisible,
      deviceGuid,
      deviceScenarioGuid,
      onAdd,
      setCurrentItem,
      currentItem,
      deleteItem,
    };
  },
});
</script>

<style>
</style>