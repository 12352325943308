import ApiService from "@/core/services/ApiService";

// 取得裝置情境對應指令關聯
export function getDeviceScenarioMappings(deviceGuid,deviceScenarioGuid): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Device/${deviceGuid}/DeviceScenario/${deviceScenarioGuid}/DeviceScenarioMapping`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增裝置情境對應指令關聯
export function addDeviceScenarioMappings(deviceGuid,deviceScenarioGuid,request): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Device/${deviceGuid}/DeviceScenario/${deviceScenarioGuid}/DeviceScenarioMapping`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 移除裝置情境對應指令關聯
export function deleteDeviceScenarioMappings(deviceGuid,deviceScenarioMappingGuid): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        ApiService.delete(`Device/${deviceGuid}/DeviceScenarioMapping/${deviceScenarioMappingGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}