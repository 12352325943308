
import { computed, defineComponent, reactive, ref } from "vue";
import Dialog from "@/components/v-dialog/CustomizeDialog.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { addDeviceScenario } from "@/core/services/api/deviceScenario";
import { getDataPointCommandByDevice } from "@/core/services/api/dataPointCommand";
import { addDeviceScenarioMappings } from "@/core/services/api/deviceScenarioMapping";

export default defineComponent({
  components: {
    Dialog,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    deviceGuid: {
      type: String,
      default: "",
    },
    deviceScenarioGuid: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const dialogShow = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit("update:modelValue", val);
      },
    });
    const formRef = ref<null | HTMLFormElement>(null);
    const formData = reactive({
      dataPointCommand: "",
      sortOrder: 0,
    });
    const rules = {
      dataPointCommand: [
        { required: true, message: "請選擇點位指令", trigger: "change" },
      ],
    };
    const dataPointCommandOption: any = reactive([]);

    const onSubmit = () => {
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                dataPointCommand: formData.dataPointCommand,
                sortOrder: formData.sortOrder,
              };
              console.log("request",request)
                await addDeviceScenarioMappings(props.deviceGuid,props.deviceScenarioGuid, request).then(
                  async () => {
                    await Swal.fire("新增成功!", "", "success");
                    emit("onAdd");
                  }
                );
            }
          });
        }
      });
    };

    const setDataPointCommandOption = async () => {
      let request = await getDataPointCommandByDevice(props.deviceGuid);
      console.log("request",request)
      for (const item of request) {
        dataPointCommandOption.push({
          label: item.name,
          value: item.guid,
        });
      }
    };

    const init = async () => {
      await setDataPointCommandOption();
    };
    init();

    return {
      ...props,
      dialogShow,
      formData,
      rules,
      formRef,
      onSubmit,
      dataPointCommandOption
    };
  },
});
